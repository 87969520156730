import React from 'react';
import { useTranslation } from 'react-i18next';
import './InfoPanel.scss';
import Verca from '../../assets/verca-new.jpg';

const InfoPanel = () => {
    const { t } = useTranslation();
    return (
        <div className="info-panel">
            <div className="left">
                <img src={Verca} className="author" alt="Veronika :)" />
            </div>
            <div className="right">
                <h3>{t('About.Heading')}</h3>
                <p>{t('About.Text')}</p>
            </div>
        </div>
    );
};

export default InfoPanel;
