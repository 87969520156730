import lenochod from '../assets/illustrations/lenochod.jpg';
import lvicek from '../assets/illustrations/lvicek.jpg';
import meduza from '../assets/illustrations/meduza.jpg';
import spiciDrak from '../assets/illustrations/spici-drak.jpg';
import velryby from '../assets/illustrations/velryby.jpg';
import zajicBalon from '../assets/illustrations/zajic-balon.jpg';
import zajicRakos from '../assets/illustrations/zajic-rakos.jpg';
import zelva from '../assets/illustrations/zelva.jpg';
import hrosikGreen from '../assets/illustrations/hrosik-green.jpg';
import hrosikRed from '../assets/illustrations/hrosik-red.jpg';
import kockaDyne from '../assets/illustrations/kocka-dyne.jpg';
import meduzkaBlue from '../assets/illustrations/meduzka-blue.jpg';
import medvedKruh from '../assets/illustrations/medved-kruh.jpg';
import ovecka from '../assets/illustrations/ovecka.jpg';
import plamenak from '../assets/illustrations/plamenak.jpg';
import psychoZaba from '../assets/illustrations/psycho-zaba.jpg';
import slunecnice from '../assets/illustrations/slunecnice.jpg';
import velrybyWhite from '../assets/illustrations/velryby-white.jpg';
import vilaGreen from '../assets/illustrations/vila-green.jpg';
import vilaParez from '../assets/illustrations/vila-parez.jpg';
import vilaRed from '../assets/illustrations/vila-red.jpg';
import zirafkaGreen from '../assets/illustrations/zirafka-green.jpg';
import zirafkaPink from '../assets/illustrations/zirafka-pink.jpg';
import pandaCervena from '../assets/illustrations/panda-cervena.jpg';
import duch from '../assets/illustrations/duch.jpg';

export const ILLUSTRATIONS = {
    lenochod,
    lvicek,
    meduza,
    spiciDrak,
    velryby,
    zajicBalon,
    zajicRakos,
    zelva,
    hrosikGreen,
    hrosikRed,
    kockaDyne,
    meduzkaBlue,
    medvedKruh,
    ovecka,
    plamenak,
    psychoZaba,
    slunecnice,
    velrybyWhite,
    vilaGreen,
    vilaParez,
    vilaRed,
    zirafkaGreen,
    zirafkaPink,
    pandaCervena,
    duch,
};

export const DIGITAL_PAINTINGS = {};

export const modalExpandedWidth = '450px';
