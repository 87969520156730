import { FOR_KIDS } from '../../helpers/forKidsConstants';

export const forKids = [
    {
        id: 'fk-01',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.chobotnickaRed,
    },
    {
        id: 'fk-02',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.duchDlan,
    },
    {
        id: 'fk-03',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.jednorozecBig,
    },
    {
        id: 'fk-04',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.muffinDlan,
    },
    {
        id: 'fk-05',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.stacked,
    },
    {
        id: 'fk-06',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.pavoukDlan,
    },
    {
        id: 'fk-07',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.plejtvakDlan,
    },

    {
        id: 'fk-11',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.vcelaDlan,
    },
    {
        id: 'fk-12',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.vcelaDlan1,
    },
    {
        id: 'fk-13',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.velrybaDlan,
    },
    {
        id: 'fk-16',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.zelvaDlan,
    },
    {
        id: 'fk-14',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.zalozkyCandy,
    },
    {
        id: 'fk-15',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.zalozkyDuha,
    },
    {
        id: 'fk-08',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.samolepkyDuha,
    },
    {
        id: 'fk-09',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.samolepkyOcean,
    },
    {
        id: 'fk-10',
        name: '',
        alt: '',
        description: '',
        size: '',
        src: FOR_KIDS.samolepkyVcely,
    },
];
