import React from 'react';
import ImageCardList from '../../components/ImageCardList/ImageCardList';

const WebGraphics = () => {
    return (
        <div className="main">
            <ImageCardList images={[]} />
        </div>
    );
};

export default WebGraphics;
