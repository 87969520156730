import React from 'react';
import Header from '../../components/Header/Header';
import InfoPanel from '../../components/InfoPanel/InfoPanel';
import hero from '../../assets/hero.png';

const Home = () => {
    return (
        <div className="main">
            <Header />
            <InfoPanel />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 40,
                    marginBottom: 40,
                }}
            >
                <img
                    alt="hero"
                    src={hero}
                    style={{
                        width: '60%',
                        height: 'auto',
                    }}
                />
            </div>
        </div>
    );
};

export default Home;
