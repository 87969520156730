import chobotnickaRed from '../assets/forKids/chobotnicka-cervena.jpeg';
import duchDlan from '../assets/forKids/duch-dlan.jpeg';
import jednorozecBig from '../assets/forKids/jednorozec-big.jpeg';
import muffinDlan from '../assets/forKids/muffin-dlan.jpeg';
import stacked from '../assets/forKids/na-sobe.jpeg';
import pavoukDlan from '../assets/forKids/pavouk-dlan.jpeg';
import plejtvakDlan from '../assets/forKids/plejtvak-dlan.jpeg';
import samolepkyDuha from '../assets/forKids/samolepky-duha.jpeg';
import samolepkyOcean from '../assets/forKids/samolepky-ocean.jpeg';
import samolepkyVcely from '../assets/forKids/samolepky-vcely.jpeg';
import vcelaDlan from '../assets/forKids/vcela-dlan.jpeg';
import vcelaDlan1 from '../assets/forKids/vcela1-dlan.jpeg';
import velrybaDlan from '../assets/forKids/velryba-dlan.jpeg';
import zalozkyCandy from '../assets/forKids/zalozky-candy.jpeg';
import zalozkyDuha from '../assets/forKids/zalozky-duha.jpeg';
import zelvaDlan from '../assets/forKids/zelva-dlan.jpeg';

export const FOR_KIDS = {
    chobotnickaRed,
    duchDlan,
    jednorozecBig,
    muffinDlan,
    stacked,
    pavoukDlan,
    plejtvakDlan,
    samolepkyDuha,
    samolepkyOcean,
    samolepkyVcely,
    vcelaDlan,
    vcelaDlan1,
    velrybaDlan,
    zalozkyCandy,
    zalozkyDuha,
    zelvaDlan,
};
