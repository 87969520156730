import React from 'react';
import ImageCardList from '../../components/ImageCardList/ImageCardList';
import { forKids } from '../../assets/forKids/forKids';

const ForKids = () => {
    return (
        <div className="main">
            <ImageCardList images={forKids} />
        </div>
    );
};

export default ForKids;
