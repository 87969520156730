import {
    ILLUSTRATIONS,
    modalExpandedWidth,
} from '../../helpers/imageConstants';

export const illustrations = [
    {
        id: 'illustration-16',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.slunecnice,
    },
    {
        id: 'illustration-30',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.pandaCervena,
    },
    {
        id: 'illustration-15',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.kockaDyne,
    },
    {
        id: 'illustration-29',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.duch,
    },
    {
        id: 'illustration-14',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.psychoZaba,
    },
    {
        id: 'illustration-28',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.vilaParez,
    },
    {
        id: 'illustration-27',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.vilaGreen,
    },
    {
        id: 'illustration-26',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.vilaRed,
    },
    {
        id: 'illustration-25',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.ovecka,
    },
    {
        id: 'illustration-24',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.zirafkaPink,
    },
    {
        id: 'illustration-23',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.zirafkaGreen,
    },
    {
        id: 'illustration-22',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.hrosikRed,
    },
    {
        id: 'illustration-21',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.hrosikGreen,
    },
    {
        id: 'illustration-20',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.plamenak,
    },
    {
        id: 'illustration-18',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.velrybyWhite,
    },
    {
        id: 'illustration-13',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.meduzkaBlue,
    },
    {
        id: 'illustration-12',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.spiciDrak,
        width: modalExpandedWidth,
    },
    {
        id: 'illustration-11',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.zajicRakos,
        width: modalExpandedWidth,
    },
    {
        id: 'illustration-17',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.medvedKruh,
    },
    {
        id: 'illustration-5',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.lvicek,
    },
    {
        id: 'illustration-6',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.lenochod,
        width: modalExpandedWidth,
    },
    {
        id: 'illustration-7',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.zajicBalon,
        width: modalExpandedWidth,
    },
    {
        id: 'illustration-8',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.velryby,
        width: modalExpandedWidth,
    },
    {
        id: 'illustration-9',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.zelva,
        width: modalExpandedWidth,
    },
    {
        id: 'illustration-10',
        name: '',
        alt: '',
        description: '',
        size: '',
        date: '2022',
        src: ILLUSTRATIONS.meduza,
        width: modalExpandedWidth,
    },
];
