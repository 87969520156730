import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import TopNavBar from './components/TopNavBar/TopNavBar';
import Home from './views/Home/Home';
import Illustrations from './views/Illustrations/Illustrations';
import Footer from './components/Footer/Footer';
import ForKids from './views/ForKids/ForKids';
import WebGraphics from './views/WebGraphics/WebGraphics';
import Paintings from './views/Paintings/Paintings';

const App = () => {
    return (
        <div className="App">
            <Router>
                <TopNavBar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/ilustrace" element={<Illustrations />} />
                    <Route path="/tvorba-pro-deti" element={<ForKids />} />
                    <Route path="/webova-grafika" element={<WebGraphics />} />
                    <Route path="/malba" element={<Paintings />} />
                </Routes>
                <Footer />
            </Router>
        </div>
    );
};

export default App;
