import React from 'react';
import ImageCardList from '../../components/ImageCardList/ImageCardList';
import { paintings } from '../../assets/paintings/paintings';

const Paintings = () => {
    return (
        <div className="main">
            <ImageCardList images={paintings} />
        </div>
    );
};

export default Paintings;
